export const fieldLabels = {
  court: "Gericht",
  deviatingCourts: "Fehlerhaftes Gericht",
  fileNumbers: "Aktenzeichen",
  deviatingFileNumbers: "Abweichendes Aktenzeichen",
  decisionDate: "Entscheidungsdatum",
  deviatingDecisionDates: "Abweichendes Entscheidungsdatum",
  documentType: "Dokumenttyp",
  deviatingEclis: "Abweichender ECLI",
  procedure: "Vorgang",
  previousProcedures: "Vorgang Historie",
  legalEffect: "Rechtskraft",
  region: "Region",
  leadingDecisionNormReferences: "BGH Nachschlagewerk",
}
